<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">

        <el-input placeholder="教师姓名或班级名称" v-model="searchForm.where_str" size="small" class="input-with-select"
          style="width:200px" clearable></el-input>
        <el-date-picker size="small" v-model="dateArray" type="daterange" align="right" unlink-panels range-separator="至"
          start-placeholder="填报开始日期" end-placeholder="填报结束日期" :picker-options="pickerOptions" class="input-with-select"
          style="width:400px;margin-left: 10px" value-format="yyyy-MM-dd"></el-date-picker>

        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
          @click="() => { page.current_page = 1; getList() }">搜索</el-button>

        <el-button icon="el-icon-plus" style="margin-left:10px" size="small" type="primary"
          @click="saveJh">添加案例</el-button>
      </div>

      <div class="flexStart"
        style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

        <el-button v-if="$store.state.config.sync_data" size="mini" icon="el-icon-refresh" @click="tongbuJava"
          type="primary" plain style="margin-left: 10px;">迁移老版本</el-button>
        <el-button size="mini" icon="el-icon-download" type="primary" plain style="margin-left: 10px;"
          @click="exportXLS">导出XLS</el-button>
        <el-button v-if="$store.state.pmid=215&&isAdmin" size="mini" icon="el-icon-download" type="primary" plain style="margin-left: 10px;"
          @click="exportPDF">导出PDF</el-button>
      </div>
    </el-row>
    <div class="dividerBar"></div>
    <el-table v-tableFit :data="DataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" width="55" label="序号"></el-table-column>
      <el-table-column prop="teacher_name" label="教师姓名" min-width="100"></el-table-column>
      <el-table-column prop="class_name" label="班级名称" min-width="100"></el-table-column>
      <!-- <el-table-column prop="case_content" label="案例内容" min-width="200"></el-table-column> -->
      <el-table-column prop="report_time" label="填报时间" min-width="150"></el-table-column>
      <el-table-column prop="create_name" label="创建人" min-width="100"></el-table-column>
      <el-table-column v-if="$store.state.pmid == 214" prop="total" label="累计次数" min-width="100"></el-table-column>
      <el-table-column prop="create_date" label="创建时间" min-width="200"></el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-tag class="pointer" type="success" size="mini" v-if="$store.state.teacher.id == scope.row.teacher_id"
            @click="viewInfo(scope.row)">编辑</el-tag>
          <el-tag class="pointer" type="primary" size="mini" v-else @click="viewInfo(scope.row)">详情</el-tag>
          <el-tag v-if="!$store.getters.isGradeLeader(scope.row.class_id)" class="pointer ml10" type="danger" size="mini" @click="deleteJh(scope.row)">删除</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <formdialog v-if="dialogshow" :id="EditItem.id"></formdialog>
  </div>
</template>

<script>
import formdialog from "./formdialog.vue";
import util from "@/utils/util";
import { export_json_to_excel } from "../../../public/js/Export2Excel";
export default {
  components: {
    formdialog
  },
  data() {
    return {
      dateArray: [],
      thisTeacherid: 0,
      dialogshow: false,
      searchForm: {
        where_str: "",
        sta_date: "",
        end_date: ""
      },
      EditItem: {
        teacherClassList: [],
        is_draft: 1,
        title1: "",
        id: 0,
        remarks: "",
        class_name: "",
        class_id: 0,
        teacher_name: "",
        teacher_id: 0,
        create_id: 0,
        case_content: "",
        report_time: "",
        create_name: "",
        sort: 99
      },
      DataList: [],
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      isAdmin: false,

    };
  },
  mounted() {
    this.getList();
    this.$http.post("/api/get_teacher_info").then(res => {
      if (res.data.sys_roles) {
        for (let item of res.data.sys_roles) {
          if (item.role_name == '系统管理员' || item.role_name == '校领导' || item.role_name == '年级组长' || item.role_code.indexOf("admin") >= 0) {
            this.isAdmin = true
            break
          }
        }
      }
    })
  },
  methods: {
    getList() {
      this.$http
        .post("/api/typical_case_list", {
          page: this.page.current_page,
          pagesize: this.page.per_page,
          where_str: this.searchForm.where_str,
          sdate: this.dateArray.length > 0 ? this.dateArray[0] : "",
          edate: this.dateArray.length > 0 ? this.dateArray[1] : ""
        })
        .then(res => {
          this.DataList = res.data.data;
          this.page = res.data.page;
        });
    },
    deleteJh(row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http
            .post("/api/typical_case_delete", { id: row.id })
            .then(res => {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
            });
          this.getList();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    addNew() {
      this.EditItem.id = 0;
      this.dialogshow = true;
    },
    saveJh() {
      this.EditItem = {
        teacherClassList: [],
        is_draft: 1,
        title1: "添加案例",
        id: 0,
        remarks: "",
        class_name: "",
        class_id: "",
        teacher_name: "",
        teacher_id: 0,
        create_id: 0,
        case_content: "",
        report_time: "",
        create_name: "",
        sort: 99
      };
      this.$http.post("/api/teacher_class_list").then(res => {
        this.EditItem.teacherClassList = res.data.class_list;
        if (this.EditItem.teacherClassList.length > 0) {
          this.EditItem.class_id = this.EditItem.teacherClassList[0].id;
        }
        this.EditItem.teacher_id = res.data.teacher_id;
        this.EditItem.teacher_name = res.data.teacher_name;
      });
      this.dialogshow = true;
    },
    viewInfo(row) {
      this.EditItem = { ...row };
      this.$http
        .post("/api/teacher_class_list", { teacher_id: row.teacher_id })
        .then(res => {
          if (this.$store.state.teacher.id == row.create_id) {
            //我发起的
            this.EditItem.teacherClassList = res.data.class_list;
            this.EditItem.class_id = Number(this.EditItem.class_id);
            this.EditItem.teacher_id = res.data.teacher_id;
            this.EditItem.teacher_name = res.data.teacher_name;
          } else {
            //不是我的
            this.EditItem.teacherClassList = [
              { id: Number(row.class_id), name: row.class_name }
            ];
            this.EditItem.class_id = Number(this.EditItem.class_id);
          }
        });
      this.EditItem.title1 = "案例详情";
      this.dialogshow = true;
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_typical_case", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    exportXLS() {
      this.$http.post('/api/typical_case_list', {
        pagesize: 10000,
        where_str: this.searchForm.where_str,
        sdate: this.dateArray.length > 0 ? this.dateArray[0] : "",
        edate: this.dateArray.length > 0 ? this.dateArray[1] : ""
      }).then(res => {
        let data = res.data.data
        if (!data.length) {
          this.$message.warning('没有数据导出')
          return
        }
        let excelData = []
        data.map((item, index) => {
          excelData.push([
            ++index,
            item.teacher_name,
            item.class_name,
            item.case_content.replace(/<[^>]+>/g, ''),
            item.create_name,
            item.create_date,
          ])
        })
        export_json_to_excel(['序号', '教师姓名', '班级名称', '案例内容', '创建人', '填报时间'], excelData, '实习案例导出')
      })
    },
    exportPDF() {
      let params = {
        "stuArr": [{ name: '实习管理典型案例' }],
        "pmid": window.pmid,
        "latest": 1
      }
      this.$http.post(this.$pdfUrl + "/export_sxglcase_teacher_all", params).then(res => {
        this.$message({
          type: 'success',
          message: '生成成功!'
        });
        window.open(res.data.url, "_blank")
      })
    }
  }
};
</script>

<style scoped="scoped">
.spuser {
  display: inline-block;
  border: 1px solid rgb(255, 148, 62);

  padding: 2px;
  margin-right: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 4px 10px;
}

.spuser img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
}
</style>
